import { Injectable, HostListener } from "@angular/core";
import { BehaviorSubject, Observable, Subscriber } from "rxjs";

// Menu
export interface Menu {
  path?: string;
  title?: string;
  icon?: string;
  type?: string;
  badgeType?: string;
  badgeValue?: string;
  active?: boolean;
  bookmark?: boolean;
  children?: Menu[];
}

@Injectable({
  providedIn: "root",
})
export class NavService {
  public screenWidth: any;
  public collapseSidebar: boolean = false;

  constructor() {
    this.onResize();
    if (this.screenWidth < 991) {
      this.collapseSidebar = true;
    }
  }

  // Windows width
  @HostListener("window:resize", ["$event"])
  onResize(event?) {
    this.screenWidth = window.innerWidth;
  }

  MENUITEMS: Menu[] = [
    {
      title: "Dashboard",
      path: "/dashboard",
      icon: "home",
      type: "link",
    },
  ];
  /*   MENUITEMS: Menu[] = [
    {
      title: "Dashboard",
      path: "/dashboard",
      icon: "home",
      type: "link",
    },
    {
      title: "Colleagues",
      path: "/dashboard/user/colleagues",
      icon: "home",
      type: "link",
    },
    {
      title: "Team Member",
      path: "/dashboard/user/team",
      icon: "home",
      type: "link",
    },
    {
      title: "Applications",
      path: "/dashboard/user/applications",
      icon: "home",
      type: "link",
    },
    {
      title: "Reports",
      path: "/contact/contacts",
      icon: "home",
      type: "link",
    },
  ]; */
  items = new BehaviorSubject<Menu[]>(this.MENUITEMS);
}
