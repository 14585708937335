// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  firebase: {
    projectId: "intellifriend-f4917",
    appId: "1:502615438771:web:2c186b14111e85870b07c6",
    storageBucket: "intellifriend-f4917.appspot.com",
    apiKey: "AIzaSyDAwHWuDIFXI3QNVmPhOoZvvid_a_MWuPY",
    authDomain: "intellifriend-f4917.firebaseapp.com",
    messagingSenderId: "502615438771",
    measurementId: "G-2D1M9DPYPQ",
  },
  production: false,
  apiBaseUrl: "https://dev.intellifriend.api.ktinformatik.com",
  // apiBaseUrl: "https://localhost:3030",

  firebaseConfig: {
    apiKey: "AIzaSyDAwHWuDIFXI3QNVmPhOoZvvid_a_MWuPY",
    authDomain: "intellifriend-f4917.firebaseapp.com",
    projectId: "intellifriend-f4917",
    storageBucket: "intellifriend-f4917.appspot.com",
    messagingSenderId: "502615438771",
    appId: "1:502615438771:web:2c186b14111e85870b07c6",
    measurementId: "G-2D1M9DPYPQ",
  },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
