<div class="team-list-body container">


  <div class="d-block">
    <h3 class="d-inline">Teams</h3>
    <button class="btn-lg btn-success float-right d-inline" (click)="openModal()">ADD NEW TEAM</button>
  </div>

  <br><br>
  <div class="conttainer">
    <h4 class=" container mb-3 warn  py-3" [ngStyle]="{'display':display4}">Team Successfully Edited!</h4>
    <h4 class=" container mb-3 warn  py-3" [ngStyle]="{'display':display3}">Team Successfully Created!</h4>
  </div>
  <div class="table-responsive container list-table" [ngStyle]="{'display':display5}">
    <table class="">
      <thead>
        <tr>
          <th scope="col" class="text-secondary">Created </th>
          <th scope="col" class="text-secondary">Name</th>
          <th scope="col" class="text-secondary">Members</th>
          <th scope="col" class="text-secondary"></th>
        </tr>
      </thead>

      <tbody *ngFor="let team of existingTeams">
        <tr class="table-row">
          <td class="">
            <h5 class="my-3">{{team.createdOn.slice(0,10)}}</h5>
          </td>
          <td class="">

            <h5 class="my-3 team-name" [routerLink]="['/dashboard/user/editTeam', team._id]">{{team.name}}</h5>

          </td>
          <td class="">
            <h5 class="my-3">{{team.members}}</h5>
          </td>
          <td class="float-right">
            <button (click)="openModal2(team._id)" class="btnIcon text-secondary">
              <h5 class="my-3"><i class="icofont icofont-edit "></i></h5>
            </button>
            <button (click)="deleteTeam(team._id)" class="btnIcon text-secondary">
              <h5 class="my-3"> <i class="icofont icofont-ui-delete"></i></h5>
            </button>
          </td>
        </tr>
      <tbody>



    </table>
  </div>


  <div [ngStyle]="{'display':display6}">
    <div class="empty-list d-flex align-items-center justify-content-center py-5">
      <div class="">
        <h1 class="text-center"><i class="icofont icofont-users-alt-3"></i></h1>
        <h5 class="text-center">You Don't have any teams yet</h5>
        <h5 class="text-center">Add teams to devide team members in team</h5>
        <br>
        <h6 class="learn text-center text-success">LEARN HOW TO ADD TEAM</h6>
      </div>
    </div>
  </div>

  <div class="modal" tabindex="-1" role="dialog" [ngStyle]="{'display':display}">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header bg-success">
          <h4 class="modal-title fw-bold">Add Team</h4>
          <button type="button" class="close" aria-label="Close" (click)="onCloseHandled()"><span
              aria-hidden="true">&times;</span></button>
        </div>
        <div class="modal-body">
          <h5 class="modal-team">Team: </h5>
          <form (submit)="onCreateHandled()">
            <div class="input-group input-group-lg">
              <input type="text" name="teamName" [(ngModel)]="teamName" class="form-control"
                aria-label="Sizing example input" aria-describedby="inputGroup-sizing-lg">
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-outline-dark" (click)="onCloseHandled()">CLOSE</button>
              <button type="submit" class="btn btn-success">CREATE</button>
            </div>
          </form>
        </div>

      </div>
    </div>
  </div>
  <div class="modal" tabindex="-1" role="dialog" [ngStyle]="{'display':display2}">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header bg-success">
          <h4 class="modal-title fw-bold">Edit</h4>
          <button type="button" class="close" aria-label="Close" (click)="onCloseHandled2()"><span
              aria-hidden="true">&times;</span></button>
        </div>
        <div class="modal-body">
          <h5 class="modal-team">Team: </h5>
          <form (submit)="onEditHandled()">
            <div class="input-group input-group-lg">
              <input type="text" name="editedTeamName" [(ngModel)]="editedTeamName" class="form-control"
                aria-label="Sizing example input" aria-describedby="inputGroup-sizing-lg">
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-outline-dark" (click)="onCloseHandled2()">CLOSE</button>
              <button type="submit" class="btn btn-success">CREATE</button>
            </div>
          </form>
        </div>

      </div>
    </div>
  </div>
</div>