import { Component, OnInit } from "@angular/core";
import { AuthService } from "@app/shared/services/auth/auth.service";
import {
  ActivatedRoute,
  Router,
  NavigationEnd,
  PRIMARY_OUTLET,
  Params,
} from "@angular/router";

import { filter } from "rxjs/operators";
import { map } from "rxjs/internal/operators";

@Component({
  selector: "app-breadcrumb",
  templateUrl: "./breadcrumb.component.html",
  styleUrls: ["./breadcrumb.component.scss"],
})
export class BreadcrumbComponent implements OnInit {
  public breadcrumbs;
  public title: string;
  public urlnext: string;
  public urlprev: string;
  public strDate: string;
  isToday: boolean;
  public user = {
    firstname: "",
    lastname: "",
    email: "",
    _id: "",
  };
  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    public authService: AuthService
  ) {
    this.user = this.authService.getUserData();

    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .pipe(map(() => this.activatedRoute))
      .pipe(
        map((route) => {
          while (route.firstChild) {
            route = route.firstChild;
          }
          return route;
        })
      )
      .pipe(filter((route) => route.outlet === PRIMARY_OUTLET))
      .subscribe((route) => {
        let snapshot = this.router.routerState.snapshot;
        let title = route.snapshot.data["title"];
        let parent = route.parent.snapshot.data["breadcrumb"];
        let child = route.snapshot.data["breadcrumb"];
        let url = route.snapshot.url;

        const getDateFormat = (date) => {
          const year = date.getFullYear();
          const month = date.getMonth() + 1;
          const day = date.getDate();
          let strMonth;
          let strDay;
          if (month < 10) {
            strMonth = `0${month}`;
          } else strMonth = month.toString();
          if (day < 10) {
            strDay = `0${day}`;
          } else strDay = day.toString();

          let today = `${year}${strMonth}${strDay}`;
          return today;
        };
        function getNextDate(dateStr) {
          let date = new Date(
            dateStr.slice(0, 4),
            dateStr.slice(4, 6) - 1,
            dateStr.slice(6, 8)
          );
          date.setDate(date.getDate() + 1);
          let year = date.getFullYear().toString();
          let month = (date.getMonth() + 1).toString().padStart(2, "0");
          let day = date.getDate().toString().padStart(2, "0");
          return year + month + day;
        }

        function formatDate(dateStr: any) {
          let date = new Date(
            dateStr.slice(0, 4),
            parseInt(dateStr.slice(4, 6)) - 1,
            parseInt(dateStr.slice(6, 8))
          );
          // console.log(date.toLocaleDateString("en-Us"));
          let monthNames = [
            "January",
            "February",
            "March",
            "April",
            "May",
            "June",
            "July",
            "August",
            "September",
            "October",
            "November",
            "December",
          ];
          let month = monthNames[date.getMonth()];
          let day = date.getDate();
          let year = date.getFullYear();
          let formattedDate = month + " " + day + ", " + year;
          // return date.toLocaleDateString("en-Us");
          return formattedDate;
          // return date.toLocaleDateString("en-US", options);
        }

        function getPrevDate(dateStr) {
          let date = new Date(
            dateStr.slice(0, 4),
            dateStr.slice(4, 6) - 1,
            dateStr.slice(6, 8)
          );
          date.setDate(date.getDate() - 1);
          let year = date.getFullYear().toString();
          let month = (date.getMonth() + 1).toString().padStart(2, "0");
          let day = date.getDate().toString().padStart(2, "0");
          return year + month + day;
        }
        const date = new Date();

        const formattedToDay = getDateFormat(date);
        this.isToday = false;

        if (url[3]?.path === "day") {
          const formattedDate = url[2].path;
          console.log(formattedDate, url, "in format");

          const previousDate = getPrevDate(formattedDate);
          const nextDate = getNextDate(formattedDate);
          this.strDate = formatDate(formattedDate);
          if (formattedToDay === formattedDate) {
            this.urlnext = `dashboard/user`;
            this.isToday = true;
          }
          this.urlprev = `dashboard/user/${this.user._id}/${previousDate}/day`;
          this.urlnext = `dashboard/user/${this.user._id}/${nextDate}/day`;
        } else {
          const previousDate = getPrevDate(formattedToDay);
          // const formattedYesterday = getDateFormat(previousDate);
          this.urlnext = "dashboard/user";
          this.isToday = true;
          // this.urlprev = `http://localhost:4201/dashboard/user/${this.user._id}/${previousDate}/day;`;
          this.urlprev = `dashboard/user/${this.user._id}/${previousDate}/day`;
          console.log(this.urlprev);
          this.strDate = formatDate(formattedToDay);
        }

        this.breadcrumbs = {};
        this.title = title;
        this.breadcrumbs = {
          parentBreadcrumb: parent,
          childBreadcrumb: child,
        };
      });
  }
  reload = () => {
    /*  setTimeout(() => {
      window.location.reload();
    }, 500); */
  };

  ngOnInit() {}

  ngOnDestroy() {}
}
