import { Routes } from "@angular/router";

export const content: Routes = [
  {
    path: "dashboard",
    loadChildren: () =>
      import("@pages/dashboard/dashboard.module").then(
        (m) => m.DashboardModule
      ),
    data: {
      breadcrumb: "Dashboard",
    },
  },
];
