import { Component, OnInit } from "@angular/core";
import emailjs, { EmailJSResponseStatus } from "@emailjs/browser";
import { environment } from "@environments/environment";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { AuthService } from "@app/shared/services/auth/auth.service";
import { FormBuilder } from "@angular/forms";
import { Router } from "@angular/router";

@Component({
  selector: "app-create-password",
  templateUrl: "./create-password.component.html",
  styleUrls: ["./create-password.component.scss"],
})
export class CreatePasswordComponent implements OnInit {
  public user;
  constructor(
    public authService: AuthService,
    private fb: FormBuilder,
    private router: Router,
    private http: HttpClient
  ) {
    this.user = this.authService.getUserData();
    if (!this.user?.isForgetPw) {
      this.router.navigateByUrl("/dashboard");
    }
  }
  inputVerificationCode: number;
  verificationCode: number;

  inputEmail: string;
  inputCode: any;
  newPw: any;
  newConfirmPw: any;
  private baseUrl: string = environment.apiBaseUrl;

  changeThePassword() {
    if (this.inputCode == this.verificationCode) {
      if (this.newPw == this.newConfirmPw) {
        alert("Send the post request here");
        const url: string = this.baseUrl + "/users/" + this.user._id;
        const accessToken = localStorage.getItem("access_token");
        const headers = new HttpHeaders()
          .set("Authorization", `Bearer ${accessToken}`)
          .set("Content-Type", "application/json");
        const data = {
          password: this.newPw,
        };
        this.http.patch(url, data, { headers }).subscribe(
          (response) => {
            console.log("User updated successfully", response);
            // this.userData = { ...this.userData, data };
            // window.localStorage.removeItem("user");
            window.localStorage.setItem("user", JSON.stringify(response));
            this.router.navigateByUrl("/dashboard");
          },
          (error) => {
            console.error("Failed to update user", error);
          }
        );
      } else {
        alert("password mismatched");
      }
    } else if (this.inputCode !== this.verificationCode) {
      alert("Code Error. Check your mail");
    }
  }
  ngOnInit(): void {}
}
