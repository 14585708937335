import { Component, OnInit, ViewEncapsulation } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { AuthService } from "@app/shared/services/auth/auth.service";
import { id } from "@swimlane/ngx-charts";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import Swal from "sweetalert2";
import { environment } from "@environments/environment";
@Component({
  selector: "app-team-page",
  templateUrl: "./team-page.component.html",
  styleUrls: ["./team-page.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class TeamPageComponent implements OnInit {
  display = "none";
  display2 = "none";
  display3 = "none";
  display4 = "none";
  display5 = "none";
  display6 = "none";

  constructor(private http: HttpClient) {}
  public baseUrl: string = environment.apiBaseUrl;

  teamName: string;
  editedTeamName: string;
  editId: string;
  //
  team: {};
  teams: { id: number; teamName: string; members: number; createdOn: string }[];
  monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  month: string;
  monthNumber: any;
  teamslength: number = 0;
  existingTeams: any = JSON.parse(localStorage.getItem("teams"));

  openModal() {
    this.display = "block";
    this.display3 = "none";
    this.display4 = "none";
  }
  onCloseHandled() {
    this.display = "none";
  }
  openModal2(id: string) {
    this.display2 = "block";
    this.editId = id;
    this.display3 = "none";
    this.display4 = "none";
  }
  onCloseHandled2() {
    this.display2 = "none";
  }
  onCreateHandled() {
    this.display3 = "none";
    this.display4 = "none";
    console.log("create team clicked", this.teamName);
    // this.teamName = '';
    this.onCloseHandled();
    let today = new Date();
    let dateString = today.toString();
    let index = 0;
    if (this.existingTeams) {
      index = this.existingTeams.length;
    }
    this.monthNumber = today.getMonth() + 1;
    this.month = this.monthNames[this.monthNumber];
    const dateStr =
      this.month + " " + dateString.slice(8, 10) + ", " + today.getFullYear();
    const newDate = new Date(dateStr);
    const formattedDate = newDate.toLocaleDateString();
    console.log(formattedDate, "New Date");

    this.team = {
      name: this.teamName,
      members: 0,
      membersId: [],
      lead: { id: "", name: "" },
      createdOn: formattedDate,
    };

    if (this.existingTeams === null) {
      this.existingTeams = [];
    }

    // localStorage.setItem("teams", JSON.stringify(this.existingTeams));
    const data = this.team;
    const url = this.baseUrl + "/teams";
    const accessToken = localStorage.getItem("access_token");
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      }),
    };
    console.log(data, "before send");

    this.http.post(url, data, httpOptions).subscribe(
      (response) => {
        console.log(response);
        this.existingTeams.push(response);
        localStorage.setItem("teams", JSON.stringify(this.existingTeams)); // Handle the response here
      },
      (error) => {
        console.error(error); // Handle any errors here
      }
    );

    this.display3 = "block";
    this.display5 = "block";
    this.display6 = "none";
  }
  onEditHandled() {
    this.display3 = "none";
    this.display4 = "none";
    console.log("edit edit");
    console.log("new team name", this.editedTeamName);
    console.log("editID", this.editId);

    let result2: any = this.existingTeams.find((e) => isFound(e, this.editId));
    console.log(result2, "Result");

    // const index = this.existingTeams.indexOf(result2);
    result2.name = this.editedTeamName;
    // this.existingTeams[index] = result2;
    // localStorage.setItem("teams", JSON.stringify(this.existingTeams));
    // Post Edit

    const url = this.baseUrl + "/teams/" + result2._id;
    const accessToken = localStorage.getItem("access_token");
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      }),
    };

    this.http.patch(url, result2, httpOptions).subscribe(
      (response) => {
        console.log(response);
        const index = this.existingTeams.indexOf(result2);
        this.existingTeams[index] = result2;
        localStorage.setItem("teams", JSON.stringify(this.existingTeams));
        // Handle the response here
      },
      (error) => {
        console.error(error); // Handle any errors here
      }
    );
    this.display4 = "block";
    function isFound(element: any, editId: string) {
      return element._id == editId;
    }
    this.onCloseHandled2();
  }

  deleteTeam(id: string) {
    this.display3 = "none";
    this.display4 = "none";
    const result = this.existingTeams.filter((e) => isDelete(e, id));

    console.log(result, "result");
    this.existingTeams = result;
    localStorage.setItem("teams", JSON.stringify(this.existingTeams));
    if (this.existingTeams.length == 0) {
      this.display6 = "block";
      this.display5 = "none";
    }
    const url = this.baseUrl + "/teams/" + id;
    const accessToken = localStorage.getItem("access_token");
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      }),
    };

    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: false,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        this.http.delete(url, httpOptions).subscribe(
          (response) => {
            console.log(response, "From delete");

            this.getTeams();

            this.existingTeams = JSON.parse(localStorage.getItem("teams"));
            location.reload();
            // this.ngOnInit();
            // this.ngOnInit()
            // Handle the response here
          },
          (error) => {
            console.error(error); // Handle any errors here
          }
        );
        Swal.fire("Deleted!", "Your file has been deleted.", "success");
        const reload = () => {
          location.reload();
        };
        setTimeout(reload, 500);

        location.reload();
      }
    });

    function isDelete(element: any, id: string) {
      console.log(id, element._id !== id);

      return element.id !== id;
    }
  }
  getTeams = () => {
    const url = this.baseUrl + "/teams";
    console.log(this.baseUrl, "Dekhi");

    fetch(url, {
      headers: {
        Accept: "application/json",
      },
    })
      .then((response) => response.json())
      .then((res) => {
        console.log(res.data);
        localStorage.removeItem("teams");
        console.log(res.data, "from get items");

        localStorage.setItem("teams", JSON.stringify(res.data));
      });
  };
  ngOnInit(): void {
    const url = this.baseUrl + "/teams";
    fetch(url, {
      headers: {
        Accept: "application/json",
      },
    })
      .then((response) => response.json())
      .then((res) => {
        console.log(res.data);
        localStorage.removeItem("teams");
        console.log(res.data, "from get items");

        localStorage.setItem("teams", JSON.stringify(res.data));
      });
    this.getTeams();
    console.log(this.existingTeams);
    this.existingTeams = JSON.parse(localStorage.getItem("teams"));

    if (this.existingTeams === null || this.existingTeams.length === 0) {
      this.teamslength = 0;
      this.display6 = "block";
      this.display5 = "none";
    } else {
      this.teamslength = this.existingTeams.length;
      this.display5 = "block";
      this.display6 = "none";
      console.log("from else", this.display6);
    }

    console.log(this.teamslength, "length");
  }
}

