<div class="container-fluid">
    <div class="authentication-main">
        <div class="row">
            <div class="col-md-12 p-0">
                <div class="auth-innerright">
                    <div class="reset-password-box">
                        <div class="text-center"><img src="assets/images/intellifriend-logo.png" alt=""></div>
                        <div class="card mt-4 mb-0">
                            <h4>Reset Your Password</h4>
                            <form class="theme-form" (ngSubmit)="changeThePassword()">



                                <h6 class="f-14 mt-4 mb-3">CREATE YOUR PASSWORD</h6>
                                <div class="form-group">
                                    <label class="col-form-label">New Password</label>
                                    <input class="form-control" type="password" name="newPw" [(ngModel)]="newPw">
                                </div>
                                <div class="form-group">
                                    <label class="col-form-label">Retype Password</label>
                                    <input class="form-control" type="password" name="newConfirmPw"
                                        [(ngModel)]="newConfirmPw">
                                </div>
                                <div class="form-group form-row mb-2">
                                    <div class="col-md-2">
                                        <button class="btn btn-primary" type="submit">Done</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>