<div class="container-fluid">
  <div class="page-header">
    <div class="row">
      <div class="col-lg-6">
        <div class="page-header-left">
          <h3>{{title}}</h3>

          <ol class="breadcrumb">
            <li class="breadcrumb-item"><a [routerLink]="'/dashboard/default'">
                <app-feather-icons [icon]="'home'"></app-feather-icons>
              </a></li>
            <li class="breadcrumb-item" *ngIf="breadcrumbs?.parentBreadcrumb">{{breadcrumbs?.parentBreadcrumb}}</li>
            <li class="breadcrumb-item active" *ngIf="breadcrumbs?.childBreadcrumb">{{breadcrumbs?.childBreadcrumb}}
            </li>
            <p class="d-inline mx-3 mb-3 text-primary date-p"> {{strDate}}</p>

          </ol>



        </div>
      </div>

      <!--    <div class="col-lg-6">
        <div class="d-inline ">

          <app-feather-icons [routerLink]=[urlprev] class="chevron-icon" (click)="reload()"
            [icon]="'chevron-left'"></app-feather-icons>
          <app-feather-icons [routerLink]=[urlnext] [ngClass]="
                isToday ? 'd-none' : 'd-inline  chevron-icon'" (click)="reload()"
            [icon]="'chevron-right'"></app-feather-icons>
        </div>
      </div> -->
      <!-- <div class="col-lg-6">
        <app-bookmark></app-bookmark>
      </div> -->
    </div>
  </div>
</div>
<!-- 
  hgf
 -->